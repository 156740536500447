<template>
  <article>
    <section class="h-48" v-if="isLoading">
      <div class="loading">
        <div class="effect-1 effects"></div>
        <div class="effect-2 effects"></div>
        <div class="effect-3 effects"></div>
      </div>
    </section>

    <section
      v-if="!isLoading"
      class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4"
    >
      <vx-card
        v-if="$gates.hasPermission('transfers_account_to_account')"
        @click.native="$router.push('/finanzas/transferencias/')"
        class="mb-base hover:shadow-lg overflow-hidden cursor-pointer"
      >
        <div slot="no-body" class="h-64 grid place-content-center">
          <div class="p-6 h-full text-center">
            <div class="h-full">
              <feather-icon
                icon="ChevronsRightIcon"
                class="p-3 inline-flex rounded-full"
                :class="[`text-primary`]"
                :style="{ background: `rgba(var(--vs-primary),.15)` }"
              ></feather-icon>
              <div class="flex flex-col mt-10">
                <div class="truncate">
                  <h2 class="mb-1 font-bold text-san-juan">
                    Crear transferencia
                  </h2>
                  <span> Realize transferencias entre cajas</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>

      <AccountCard
        v-for="account in accounts"
        :key="account.id"
        class="mb-base hover:shadow-lg cursor-pointer"
        :card-title="account.name"
        @click.native="$router.push('/finanzas/movimientos-caja/' + account.id)"
        icon="DollarSignIcon"
        :statistic="account.amount | currencyFormat"
        statisticTitle="Saldo actual"
        secondStatisticTitle="Tipo de caja"
        :secondStatistic="account.type | typeAccount"
        :chartData="revenueGenerated.series"
        color="success"
        icon-right
        type="area"
      />
    </section>
  </article>
</template>

<script>
import AccountCard from '@/components/finanzas/AccountCard.vue';
import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  components: {
    AccountCard,
  },
  data() {
    return {
      accounts: '',
      revenueGenerated: {
        series: [
          {
            name: 'Revenue',
            data: [350, 275, 400, 300, 350, 300, 450],
          },
        ],
        analyticsData: {
          revenue: 97500,
        },
      },
      isLoading: false,
    };
  },
  async mounted() {
    this.listAccounts();
  },
  methods: {
    async listAccounts() {
      try {
        this.isLoading = true;
        const { data } = await this.$repositories.accounts.listAccounts();
        this.accounts = data;
        this.isLoading = false;
      } catch {
        this.isLoading = false;
        this.$vs.notify({
          title: 'Error',
          text: 'Error al obtener las cajas.',
          color: 'danger',
        });
      }
    },
  },
});
</script>
